import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { UtilsService } from './core/services/utils.service';
import { AuthService } from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'Franchise One';
  showHeaderPartials: boolean = true;
  hideHeaderPartialsRoutes: string[] = ['/login', '/register', '/forgot-password', '/profile/complete-profile'];
  showFooterPartials: boolean = true;
  hideFooterPartialsRoutes: string[] = ['/login', '/register', '/forgot-password', '/profile', '/profile/complete-profile'];
  hideFooterOnMobileRoutes: string[] = ['/brand', '/cart', '/checkout', '/order/'];
  isMobileView: boolean = this.utils.isMobileView();
  role: string = '';

  constructor(
    private router: Router,
    private titleService: Title,
    private utils: UtilsService,
    private auth: AuthService
  ) {
    console.log('30-09-2024-11-42')
    this.titleService.setTitle(this.title);
    this.auth.theRole$.subscribe({
      next: (role: string) => {
        this.role = role;
      },
      error: (error: any) => {
        console.error('Error in header component:', error);
      }
    });
  }
  
  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.showHeaderPartials = !this.hideHeaderPartialsRoutes.some(route => evt.urlAfterRedirects.startsWith(route));
        
        const hideFooterForRoute = this.hideFooterPartialsRoutes.some(route => evt.urlAfterRedirects.startsWith(route));
        const hideFooterForMobileRoute = this.isMobileView && this.hideFooterOnMobileRoutes.some(route => evt.urlAfterRedirects.startsWith(route));
        
        this.showFooterPartials = !(hideFooterForRoute || hideFooterForMobileRoute);
      }
    });
  }
}
