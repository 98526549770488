<div class="container">
  <div class="row align-items-center mt-5 py-2">
    <div class="col-2">
      <button type="button" class="btn btn-link text-decoration-none" onclick="history.back()">
        <span class="fw-bold"><</span>
      </button>
    </div>
    <div class="col-8 text-center">
      <h5 class="m-0 fw-bold">Reset Password</h5>
    </div>
    <div class="col-2"></div>
  </div>
  <div class="row pt-5" style="height: 75vh;">
    <div class="col-12 form-container d-flex justify-content-between flex-column">
      <h2 class="text-center fw-bold text-primary">Set New Password</h2>
      <h6 class="card-title text-center text-secondary">Enter your new password</h6>
      <div style="width: 400px" class="card mx-auto border-0 flex-grow-1">
        <div class="card-body mt-5">
          <form [formGroup]="setPasswordForm" (submit)="onSubmit($event)" cForm class="h-100 d-flex flex-column">
            <div class="flex-grow-1">
              <div class="mb-3">
                <label for="password" class="fw-medium">Password</label>
                <input
                  class="form-control"
                  id="password"
                  formControlName="password"
                  type="password"
                  placeholder="enter 8 digit password"
                />
                <div
                  *ngIf="setPasswordForm.controls['password'].errors?.['required'] && setPasswordForm.controls['password'].touched"
                >
                  <small class="text-danger">Password is required</small>
                </div>
              </div>
              <div class="mb-3">
                <label for="confirmPassword" class="fw-medium">Confirm Password</label>
                <input
                  class="form-control"
                  id="confirmPassword"
                  formControlName="confirmPassword"
                  type="password"
                  placeholder="confirm your password"
                />
                <div
                  *ngIf="setPasswordForm.controls['confirmPassword'].errors?.['required'] && setPasswordForm.controls['confirmPassword'].touched"
                >
                  <small class="text-danger">Confirm password is required</small>
                </div>
              </div>
            </div>
            <div class="d-grid mt-5">
              <button
                role="button"
                class="btn btn-primary"
                type="submit"
                [disabled]="setPasswordForm.invalid">
                Reset My Password
              </button>
            </div>
            <div class="d-grid text-center mt-5">
              <p>
                Back to
                <a
                  [routerLink]="['/login']"
                  class="text-primary text-decoration-none"
                  ><strong>Login</strong></a
                >
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
