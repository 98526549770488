import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { SHARE_ICONS } from 'ngx-sharebuttons';
import { faFacebookF, faXTwitter, faLinkedinIn, faPinterestP, faRedditAlien, faTumblr, faWhatsapp, faViber, faVk, faFacebookMessenger, faTelegramPlane, faMix, faXing, faLine } from '@fortawesome/free-brands-svg-icons';
import { faSms, faEnvelope, faCheck, faPrint, faExclamation, faLink, faEllipsisH, faMinus } from '@fortawesome/free-solid-svg-icons';

const icons = [
    faFacebookF, faXTwitter, faLinkedinIn, faPinterestP, faRedditAlien, faTumblr,
    faWhatsapp, faViber, faVk, faFacebookMessenger, faTelegramPlane, faMix, faXing, faSms,
    faEnvelope, faCheck, faPrint, faExclamation, faLink, faEllipsisH, faMinus, faLine
];
function iconsLoaderFactory(iconLibrary) {
    iconLibrary.addIcons(...icons);
    return null;
}
function shareIcons() {
    return {
        provide: SHARE_ICONS,
        useFactory: iconsLoaderFactory,
        deps: [FaIconLibrary]
    };
}
/**
 * @deprecated
 * Function was renamed, use 'shareIcons' function instead
 */
const withIcons = shareIcons;

/**
 * Generated bundle index. Do not edit.
 */

export { iconsLoaderFactory, shareIcons, withIcons };

