import { Component } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CreateAccountComponent } from '../../register/create-account/create-account.component';
import { AuthService } from '../../auth.service';
import { ToastService } from '../../../core/services/toast.service';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrl: './set-password.component.scss'
})
export class SetPasswordComponent {
  setPasswordForm!: FormGroup;
  token: string = '';
  phoneNumber: string = '';

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private toast: ToastService,
    private router: Router) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras.state) {
      this.token = navigation.extras.state['token'];
      this.phoneNumber = navigation.extras.state['phoneNumber'];
    } else {
      console.log('No navigation state available');
      this.router.navigate(['/forgot-password']);
    }
  }
  
  ngOnInit(): void {
    this.setPasswordForm = this.fb.group({
      token: [this.token, Validators.required],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
    });
  }

  onSubmit(event: Event) {
    if (this.setPasswordForm.valid) {
      this.auth.resetPassword(this.token, this.setPasswordForm.value.password).subscribe({
        next: (response) => {
          this.toast.show({textOrHtml: response.message, classname: 'bg-success text-white'});
          this.login();
        },
        error: (error) => {
          console.error(error);
          this.toast.show({textOrHtml: error.error.message, classname: 'bg-danger text-white'});
        }
      }
    )}
  }
  
  login() {
    this.auth.login(this.phoneNumber, this.setPasswordForm.value.password).subscribe({
      next: (response) => {
        console.log(response);
        this.router.navigate(['/forgot-password/reset-success']);
      },
      error: (error) => {
        console.error(error);
        this.toast.show({textOrHtml: error.error.message, classname: 'bg-danger text-white'});
      }
    });
  }

}
