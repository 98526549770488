import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { inject } from '@angular/core';

export const RoleGuard: CanActivateFn = (route, state) => {
  
  const router = inject(Router);
  const auth = inject(AuthService);
  const expectedRoles = route.data['expectedRoles'];
  const userRole = auth.getUserRole();

  if (!expectedRoles.includes(userRole)) {
    router.navigate(['/not-authorized']);
    return false;
  }
  return true;
};
