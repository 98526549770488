<div class="container">
  <div class="row align-items-center mt-5 py-2">
    <div class="col-2">
      <button type="button" class="btn btn-link text-decoration-none" onclick="history.back()">
        <span class="fw-bold"><</span>
      </button>
    </div>
    <div class="col-8 text-center">
      <h5 class="m-0 fw-bold">Reset Password</h5>
    </div>
    <div class="col-2"></div>
  </div>
  <div class="row pt-5" style="height: 75vh;">
    <div class="col-12 form-container d-flex justify-content-between flex-column">
      <h2 class="text-center fw-bold text-primary">Forgot Password</h2>
      <h6 class="card-title text-center text-secondary">Enter your phone number</h6>
      <div style="width: 400px" class="card mx-auto border-0 flex-grow-1">
        <div class="card-body mt-5">
          <form [formGroup]="forgotPasswordForm" cForm (submit)="onSubmit($event)" class="h-100 d-flex flex-column">
            <div class="mb-3 flex-grow-1">
              <label for="yourPhone">Phone Number</label>
              <input class="form-control" id="yourPhone" formControlName="phoneNumber" #phoneNumber type="tel" placeholder="Phone Number" />
              <div *ngIf="forgotPasswordForm.controls['phoneNumber'].errors?.['required'] && forgotPasswordForm.controls['phoneNumber'].touched">
                <small class="text-danger">Your phone number is required</small>
              </div>
            </div>
            <div class="d-grid mt-3">
              <button role="button" class="btn btn-primary w-100" type="submit" [disabled]="forgotPasswordForm.invalid">Send Instructions</button>
            </div>
            <div class="d-grid text-center mt-3">
              <p>
                Back to
                <a
                  [routerLink]="['/login']"
                  class="text-primary text-decoration-none"
                  ><strong>Login</strong></a
                >
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
