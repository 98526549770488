import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent {
  registerForm!: FormGroup;
  activeRole: string = 'Franchisor';

  constructor(
    private router: Router,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.registerForm = this.fb.group({
      activeRole: ['', Validators.required],
    });
  }

  toggleRole(activeRole: 'Franchisor' | 'Franchisee') {
    this.activeRole = activeRole;
  }

  onSubmit(event: Event) {
    if (this.registerForm.valid) {
      const selectedRole = this.registerForm.get('activeRole')?.value;
      this.router.navigate(['/register/account-detail'], {
        state: { activeRole: selectedRole },
      });
    }
  }
}
