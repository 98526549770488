<div class="container">
  <div class="row">
    <div class="col-12 d-block d-md-none">
      <img src="../../../assets/images/f1.png" width="112" class="mx-auto mt-3 mb-2 d-block d-md-none" role="button" alt="Franchise One Logo" [routerLink]="['/']">
    </div>
  </div>
  <ol class="numbered-list list-inline">
    <li class="list-inline-item active" data-step="1"><span>Choose a Role</span></li>
    <li class="list-inline-item" data-step="2"><span>Create Account</span></li>
    <li class="list-inline-item" data-step="3"><span>Account Verification</span></li>
    <li class="list-inline-item" data-step="4"><span>Update Information</span></li>
  </ol>
  <div class="row pt-5" style="height: 75vh;">
    <div class="col-12 form-container d-flex flex-column justify-content-between">
      <h2 class="text-center fw-bold text-primary">Choose Roles</h2>
      <h6 cCardTitle class="text-center fw-normal text-secondary">Please select your role</h6>
      <div style="width: 400px; max-width: 90%" class="card flex-fill mx-auto border-0">
        <div class="card-body">
          <form [formGroup]="registerForm" (submit)="onSubmit($event)" cForm class="h-100 d-flex flex-column justify-content-between">
            <div class="flex-grow-1">
              <div class="form-check">
                <label class="form-check-label text-primary" id="activeRole">
                  <input class="form-check-input" type="radio" formControlName="activeRole" value="Franchisor" />
                  <strong>Franchisor</strong><br>
                  I'm a business owner and looking for investor to expand my business
                </label>
              </div>
              <div class="form-check">
                <label class="form-check-label text-primary" id="activeRole">
                  <input class="form-check-input" type="radio" formControlName="activeRole" value="Franchisee"/>
                  <strong>Franchisee</strong><br>
                  I'm a investor and looking to help the business owner to fulfill their needs
                </label>
              </div>
            </div>
            <div class="d-grid">
              <input role="button" class="btn btn-primary" color="primary" type="submit" value="Next" [disabled]="registerForm.invalid" />
            </div>
            <div class="d-grid text-center mt-3">
              <p>
                Have an account already?
                <a
                  [routerLink]="['/login']"
                  class="text-primary text-decoration-none"
                  ><strong>Login</strong></a
                >
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>