import { Component, QueryList, ViewChildren } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../auth.service';
import { ToastService } from '../../../core/services/toast.service';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrl: './verify-otp.component.scss',
})
export class VerifyOtpComponent {
  verifyOtpForm!: FormGroup;
  otpArray: string[] = new Array(6).fill('');
  phoneNumber: string = '';
  isSubmitting: boolean = false;

  @ViewChildren('otp0, otp1, otp2, otp3, otp4, otp5') otpInputs!: QueryList<any>;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private toast: ToastService,
    private router: Router
  ) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras.state) {
      this.phoneNumber = navigation.extras.state['phoneNumber'];
    } else {
      this.router.navigate(['/forgot-password']);
    }

  }
  
  ngOnInit(): void {
    this.verifyOtpForm = this.fb.group({
      phoneNumber: [this.phoneNumber, Validators.required],
      otp: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]]
    });
  }

  ngAfterViewInit(): void {
    this.otpInputs.toArray().forEach((input, index) => {
      input.nativeElement.addEventListener('keydown', (event: KeyboardEvent) => this.onOtpKeyDown(event, index));
    });
    const firstInput = this.otpInputs.first;
    if (firstInput) {
      firstInput.nativeElement.focus();
    }
  }

  onOtpInput(event: any, index: number) {
    const input = event.target;
    const value = input.value;

    if (/[^0-9]/.test(value)) {
      input.value = '';
      return;
    }

    this.otpArray[index] = value;

    if (value && index < 5) {
      const nextInput = this.otpInputs.toArray()[index + 1].nativeElement;
      nextInput.focus();
    }

    const otp = this.otpArray.join('');
    if (otp.length === 6 && !this.isSubmitting) {
      this.isSubmitting = true;
      this.verifyOtpForm.patchValue({ otp });
      this.submitForm();
    }
  }

  onOtpKeyDown(event: KeyboardEvent, index: number) {
    const input = event.target as HTMLInputElement;

    if (event.key === 'Backspace' || event.key === 'Delete') {
      event.preventDefault();
      this.otpArray[index] = '';
      input.value = '';

      if (index > 0 && event.key === 'Backspace') {
        const prevInput = this.otpInputs.toArray()[index - 1].nativeElement;
        prevInput.focus();
      }
    } else if (event.key === 'ArrowLeft' && index > 0) {
      event.preventDefault();
      const prevInput = this.otpInputs.toArray()[index - 1].nativeElement;
      prevInput.focus();
    } else if (event.key === 'ArrowRight' && index < 5) {
      event.preventDefault();
      const nextInput = this.otpInputs.toArray()[index + 1].nativeElement;
      nextInput.focus();
    } else if (event.key >= '0' && event.key <= '9') {
      event.preventDefault();
      input.value = event.key;
      this.otpArray[index] = event.key;

      if (index < 5) {
        const nextInput = this.otpInputs.toArray()[index + 1].nativeElement;
        nextInput.focus();
      }

      const otp = this.otpArray.join('');
      if (otp.length === 6 && !this.isSubmitting) {
        this.isSubmitting = true;
        this.verifyOtpForm.patchValue({ otp });
        this.submitForm();
      }
    }
  }

  submitForm() {
    if (this.verifyOtpForm.valid) {
      this.auth.verifyResetPasswordOtp(this.phoneNumber, this.verifyOtpForm.value.otp).subscribe({
        next: (response) => {
          this.toast.show({textOrHtml: 'Please set your new password.', classname: 'bg-success text-white', delay: 1500});
          this.router.navigate(['/forgot-password/set-password'], {
            state: { token: response.data.token, phoneNumber: this.phoneNumber },
          });
          this.isSubmitting = false;
        },
        error: (error) => {
          this.toast.show({textOrHtml: error.error.message, classname: 'bg-danger text-white', delay: 1500});
          this.isSubmitting = false;
        },
      });
    }
  }

}
