import { Component } from '@angular/core';

@Component({
  selector: 'app-reset-success',
  templateUrl: './reset-success.component.html',
  styleUrl: './reset-success.component.scss'
})
export class ResetSuccessComponent {

}
