import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router, private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('token');
    const excludedEndpoints = ['/register', '/login', '/maps/api'];
    const isExcluded = excludedEndpoints.some(url => req.url.includes(url));

    let clonedReq = req;
    if (token && !isExcluded) {
      clonedReq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${token}`)
      });
    }

    return next.handle(clonedReq).pipe(
      catchError((error: HttpErrorResponse) => {
        let retryCount = 0; // Initialize retry count
        let isRefreshing = false; // Flag to prevent multiple refresh attempts

        const handleRetry = () => {
          if (retryCount < 2 && !isRefreshing) { // Check if retry count is less than 2 and not already refreshing
            retryCount++;
            isRefreshing = true; // Set the flag to true
            return this.authService.refreshToken().pipe(
              switchMap(() => {
                isRefreshing = false; // Reset the flag after refresh
                const newToken = this.authService.getToken();
                if (!newToken) {
                  return throwError(() => new HttpErrorResponse({ status: 401, error: 'Token refresh failed' }));
                }
                const newRequest = req.clone({
                  headers: req.headers.set('Authorization', `Bearer ${newToken}`)
                });
                return next.handle(newRequest);
              }),
              catchError((refreshError: HttpErrorResponse) => {
                isRefreshing = false; // Reset the flag on error
                // Check if the error is from the refresh token endpoint
                if (refreshError.status === 401 && refreshError.url?.includes('/auth/request')) {
                  // Exit the flow without retrying
                  this.handleAuthError(refreshError);
                  return throwError(() => refreshError);
                }
                // Handle other errors from refresh token
                this.handleAuthError(refreshError);
                return throwError(() => refreshError);
              })
            );
          }
          return throwError(() => error); // No more retries
        };

        if (error.status === 401) {
          // Check if the error is from the refresh token endpoint
          if (error.url?.includes('/auth/request')) {
            // Exit the flow without retrying
            this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
            this.handleAuthError(error);
            return throwError(() => error);
          }
          return handleRetry(); // Attempt to retry on 401
        } else if (error.status === 403) {
          this.handleAuthError(error);
        }
        return throwError(() => error);
      })
    );
  }

  private handleAuthError(error: HttpErrorResponse) {
    const currentUrl = this.router.url;
    if (error.status === 403) {
      this.authService.logout();
      this.authService.getRoleSubject.next('');
      this.router.navigate(['/login'], { queryParams: { returnUrl: currentUrl } });
    }
  }
}
