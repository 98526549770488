import { Component, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../auth.service';
import { ToastService } from '../../../core/services/toast.service';

@Component({
    selector: 'app-verify-account',
    templateUrl: './verify-account.component.html',
    styleUrls: ['./verify-account.component.scss'],
})
export class VerifyAccountComponent {
  verifyOtpForm!: FormGroup;
  otpArray: string[] = new Array(6).fill('');
  accountDetail!: any;
  isSubmitting: boolean = false;

  @ViewChildren('otp0, otp1, otp2, otp3, otp4, otp5') otpInputs!: QueryList<any>;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private toast: ToastService,
    private router: Router
  ) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras.state) {
      this.accountDetail = navigation.extras.state;
    } else {
      console.log('No navigation state available');
      this.router.navigate(['/register']);
    }
  }
  
  ngOnInit(): void {
    this.verifyOtpForm = this.fb.group({
      phoneNumber: [this.accountDetail.phoneNumber, Validators.required],
      otp: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]]
    });
    this.sendCode(this.accountDetail.phoneNumber);
  }

  ngAfterViewInit(): void {
    this.otpInputs.toArray().forEach((input, index) => {
      input.nativeElement.addEventListener('keydown', (event: KeyboardEvent) => this.onOtpKeyDown(event, index));
    });
    const firstInput = this.otpInputs.first;
    if (firstInput) {
      firstInput.nativeElement.focus();
    }
  }

  onOtpInput(event: any, index: number) {
    const input = event.target;
    const value = input.value;

    if (/[^0-9]/.test(value)) {
      input.value = '';
      return;
    }

    this.otpArray[index] = value;

    if (value && index < 5) {
      const nextInput = this.otpInputs.toArray()[index + 1].nativeElement;
      nextInput.focus();
    }

    const otp = this.otpArray.join('');
    if (otp.length === 6 && !this.isSubmitting) {
      this.isSubmitting = true;
      this.verifyOtpForm.patchValue({ otp });
      this.submitForm();
    }
  }

  onOtpKeyDown(event: KeyboardEvent, index: number) {
    const input = event.target as HTMLInputElement;

    if (event.key === 'Backspace' || event.key === 'Delete') {
      event.preventDefault();
      this.otpArray[index] = '';
      input.value = '';

      if (index > 0 && event.key === 'Backspace') {
        const prevInput = this.otpInputs.toArray()[index - 1].nativeElement;
        prevInput.focus();
      }
    } else if (event.key === 'ArrowLeft' && index > 0) {
      event.preventDefault();
      const prevInput = this.otpInputs.toArray()[index - 1].nativeElement;
      prevInput.focus();
    } else if (event.key === 'ArrowRight' && index < 5) {
      event.preventDefault();
      const nextInput = this.otpInputs.toArray()[index + 1].nativeElement;
      nextInput.focus();
    } else if (event.key >= '0' && event.key <= '9') {
      event.preventDefault();
      input.value = event.key;
      this.otpArray[index] = event.key;

      if (index < 5) {
        const nextInput = this.otpInputs.toArray()[index + 1].nativeElement;
        nextInput.focus();
      }

      const otp = this.otpArray.join('');
      if (otp.length === 6 && !this.isSubmitting) {
        this.isSubmitting = true;
        this.verifyOtpForm.patchValue({ otp });
        this.submitForm();
      }
    }
  }

  submitForm() {
    if (this.verifyOtpForm.valid) {
      this.auth.verifyOtp(this.accountDetail.phoneNumber, this.verifyOtpForm.value.otp)
        .subscribe({
          next: (response: Response) => {
            this.toast.show({ textOrHtml: 'OTP verified successfully', classname: 'bg-success text-white', delay: 1500 });
            this.login();
            this.isSubmitting = false;
          },
          error: (error) => {
            this.toast.show({ textOrHtml: error.error.message, classname: 'bg-danger text-white', delay: 1500 });
            this.isSubmitting = false;
          }
        });
    } else {
      this.isSubmitting = false;
    }
  }
  
  sendCode(phone: string) {
    this.auth.sendOtp(phone).subscribe({
      next: (response: Response) => {
        this.toast.show({textOrHtml: 'OTP sent successfully!', classname: 'bg-success text-white', delay: 1500});
      },
      error: (error) => {
        console.error('Error sending OTP', error);
        this.toast.show({textOrHtml: error.error.message, classname: 'bg-danger text-white', delay: 1500});
      }
    });
  }

  login() {
    this.auth.login(this.accountDetail.phoneNumber, this.accountDetail.password).subscribe({
      next: (response: Response) => {
        this.toast.show({textOrHtml: 'Login successful', classname: 'bg-success text-white', delay: 1500});
        this.router.navigate(['/register/account-information'], {
          state: { ...this.accountDetail },
        });
      },
      error: (error) => {
        console.error('Error logging in', error);
        this.toast.show({textOrHtml: error.error.message, classname: 'bg-danger text-white', delay: 1500});
      }
    });
  }
}
