import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router, private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('token');
    const excludedEndpoints = ['/register', '/login', '/maps/api'];
    const isExcluded = excludedEndpoints.some(url => req.url.includes(url));

    let clonedReq = req;
    if (token && !isExcluded) {
      clonedReq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${token}`)
      });
    }

    return next.handle(clonedReq).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // Attempt to refresh the token if a 401 error is encountered
          return this.authService.refreshToken().pipe(
            switchMap(() => {
              const newToken = this.authService.getToken();
              const newRequest = req.clone({
                headers: req.headers.set('Authorization', `Bearer ${newToken}`)
              });
              return next.handle(newRequest);
            }),
            catchError(() => {
              this.handleAuthError(error);
              return throwError(() => error);
            })
          );
        } else if (error.status === 403) {
          // Handle 403 errors specifically
          this.handleAuthError(error);
        }
        return throwError(() => error);
      })
    );
  }

  private handleAuthError(error: HttpErrorResponse) {
    const currentUrl = this.router.url;
    if (error.status === 401 || error.status === 403) {
      this.authService.logout();
      this.router.navigate(['/login'], { queryParams: { returnUrl: currentUrl } });
    }
  }
}
