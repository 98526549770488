import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { SharedModule } from './shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastsComponent } from './core/components/toasts/toasts.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgxPaginationModule } from 'ngx-pagination';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { provideShareButtonsOptions } from 'ngx-sharebuttons';
import { shareIcons } from 'ngx-sharebuttons/icons';
import { BasicInformationComponent } from './auth/register/basic-information/basic-information.component';
import { CreateAccountComponent } from './auth/register/create-account/create-account.component';
import { VerifyAccountComponent } from './auth/register/verify-account/verify-account.component';
import { CongratulationsComponent } from './auth/register/congratulations/congratulations.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { VerifyOtpComponent } from './auth/reset-password/verify-otp/verify-otp.component';
import { SetPasswordComponent } from './auth/reset-password/set-password/set-password.component';
import { ResetSuccessComponent } from './auth/reset-password/reset-success/reset-success.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { environment } from '../environments/environment';
import { LoginManagerComponent } from './auth/login-manager/login-manager.component';

export function loadScript() {
  return () => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = environment.scriptUrl;

      // Resolve when the script is loaded
      script.onload = () => {
        console.log(`${environment.scriptUrl} loaded successfully.`);
        resolve(true);
      };

      // Reject if there's an error loading the script
      script.onerror = () => {
        console.error(`Error loading script: ${environment.scriptUrl}`);
        reject(false);
      };

      document.head.appendChild(script);
    });
  };
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    BasicInformationComponent,
    CreateAccountComponent,
    VerifyAccountComponent,
    CongratulationsComponent,
    ResetPasswordComponent,
    VerifyOtpComponent,
    SetPasswordComponent,
    ResetSuccessComponent,
    LoginManagerComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    AppRoutingModule,
    NgbModule,
    NgxPaginationModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    ToastsComponent,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: loadScript,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    provideShareButtonsOptions(
      shareIcons()
    ),
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
