<div class="container">
  <div class="row">
    <div class="col-12 d-block d-md-none">
      <img src="../../../assets/images/f1.png" width="112" class="mx-auto mt-3 mb-2 d-block d-md-none" role="button" alt="Franchise One Logo" [routerLink]="['/']">
    </div>
  </div>
  <ol class="numbered-list list-inline">
    <li class="list-inline-item done" data-step="1"><span>Choose a Role</span></li>
    <li class="list-inline-item active" data-step="2"><span>Create Account</span></li>
    <li class="list-inline-item" data-step="3"><span>Account Verification</span></li>
    <li class="list-inline-item" data-step="4"><span>Update Information</span></li>
  </ol>
  <div class="row pt-5" style="min-height: 75vh;"> 
    <div class="col-12 form-container d-flex flex-column justify-content-between">
      <h2 class="text-center fw-bold text-primary">Create your Account</h2>
      <h6 class="card-title text-center fw-normal text-secondary">Please create your account before continuing</h6>
      <div style="width: 800px; max-width: 90%;" class="card flex-fill mx-auto border-0">
        <div class="card-body mt-3">
          <form [formGroup]="accountForm" (submit)="onSubmit($event)" class="h-100 d-flex flex-column justify-content-between">
            <div class="row flex-grow-1">
              <div class="col-12 col-md-6 mx-auto">
                <div class="mb-3">
                  <label for="yourName">Full Name</label>
                  <input id="yourName" formControlName="name" type="text" class="form-control" placeholder="your full name" [ngClass]="{'is-invalid': isControlInvalid('name')}" />
                  <div *ngIf="isControlInvalid('name', 'required')" class="invalid-feedback">
                    Full name is required
                  </div>
                </div>
                <div class="mb-3">
                  <label for="phoneNumber">Phone Number</label>
                  <input id="phoneNumber" formControlName="phoneNumber" #phoneNumber type="tel" class="form-control" placeholder="your WhatsApp number" [ngClass]="{'is-invalid': isControlInvalid('phoneNumber')}" />
                  <div *ngIf="isControlInvalid('phoneNumber', 'required')" class="invalid-feedback">
                    Your WhatsApp phone number is required
                  </div>
                </div>
                <div class="mb-3">
                  <label for="yourPassword">Password</label>
                  <div class="input-group">
                    <input
                      id="yourPassword"
                      formControlName="password"
                      [type]="showPassword ? 'text' : 'password'"
                      class="form-control"
                      placeholder="8 characters alphanumeric"
                      [ngClass]="{'is-invalid': isControlInvalid('password')}"
                    />
                    <button
                      type="button"
                      class="btn btn-outline-secondary"
                      (click)="togglePasswordVisibility()"
                    >
                    <fa-icon [icon]="showPassword ? icons.faEyeSlash : icons.faEye"></fa-icon>
                    </button>
                    <div *ngIf="isControlInvalid('password', 'required')" class="invalid-feedback">
                      Password is required
                    </div>
                    <div *ngIf="isControlInvalid('password', 'minlength')" class="invalid-feedback">
                      Your password must have a minimum of 8 characters
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6" *ngIf="activeRole === 'Franchisor'">
                <div class="mb-3">
                  <label for="legalEntity">Legal Entity</label>
                  <select aria-label="Legal Entity" formControlName="entity" class="form-select" [ngClass]="{'is-invalid': isControlInvalid('entity')}">
                    <option value="" disabled>-- Select one --</option>
                    <option value="Individual">Individual</option>
                    <option value="PT">PT</option>
                    <option value="CV">CV</option>
                  </select>
                  <div *ngIf="isControlInvalid('entity', 'required')" class="invalid-feedback">
                    Select your legal entity
                  </div>
                </div>
                <div class="mb-3">
                  <label for="brandName">Name of your Brand</label>
                  <input id="brandName" formControlName="brandName" type="text" class="form-control" placeholder="your brand name" [ngClass]="{'is-invalid': isControlInvalid('brandName')}" />
                  <div *ngIf="isControlInvalid('brandName', 'required')" class="invalid-feedback">
                    Your brand name is required
                  </div>
                </div>
                <div class="mb-3">
                  <label for="foundingYear">Founding Year</label>
                  <input id="foundingYear" formControlName="founded" type="number" class="form-control" placeholder="year of business founding" [ngClass]="{'is-invalid': isControlInvalid('founded')}" />
                  <div *ngIf="isControlInvalid('founded', 'required')" class="invalid-feedback">
                    Please state when your business was founded
                  </div>
                </div>
              </div>
            </div>
            <div class="d-grid mt-3">
              <button type="submit" class="btn btn-primary col-md-6 mx-auto w-100" [disabled]="isProcessing || accountForm.invalid">
                <i *ngIf="isProcessing" class="spinner-border spinner-border-sm"></i>
                Next
              </button>
            </div>
            <div class="d-grid text-center mt-3">
              <p>
                Have an account already?
                <a
                  [routerLink]="['/login']"
                  class="text-primary text-decoration-none"
                  ><strong>Login</strong></a
                >
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>