<div class="container">
  <div class="row align-items-center mt-5 py-2">
    <div class="col-2">
      <button type="button" class="btn btn-link text-decoration-none" onclick="history.back()">
        <span class="fw-bold"><</span>
      </button>
    </div>
    <div class="col-8 text-center">
      <h5 class="m-0 fw-bold">Reset Password</h5>
    </div>
    <div class="col-2"></div>
  </div>
  <div class="row pt-5" style="height: 75vh;">
    <div class="col-12 form-container d-flex justify-content-between flex-column">
      <h2 class="text-center fw-bold text-primary">Account Verification</h2>
      <h6 cCardTitle class="text-center text-secondary">Enter the code sent to your phone number</h6>
      <div style="width: 400px" class="card mx-auto border-0 flex-grow-1">
        <div class="card-body mt-5">
          <div class="h-100 d-flex flex-column">
            <div class="mb-3 flex-grow-1">
              <div class="otp-inputs">
                <input type="text" maxlength="1" (input)="onOtpInput($event, 0)" (keydown)="onOtpKeyDown($event, 0)" #otp0 />
                <input type="text" maxlength="1" (input)="onOtpInput($event, 1)" (keydown)="onOtpKeyDown($event, 1)" #otp1 />
                <input type="text" maxlength="1" (input)="onOtpInput($event, 2)" (keydown)="onOtpKeyDown($event, 2)" #otp2 />
                <input type="text" maxlength="1" (input)="onOtpInput($event, 3)" (keydown)="onOtpKeyDown($event, 3)" #otp3 />
                <input type="text" maxlength="1" (input)="onOtpInput($event, 4)" (keydown)="onOtpKeyDown($event, 4)" #otp4 />
                <input type="text" maxlength="1" (input)="onOtpInput($event, 5)" (keydown)="onOtpKeyDown($event, 5)" #otp5 />
              </div>
            </div>
            <div class="d-grid mt-3">
              <button role="button" class="btn btn-primary" type="button" (click)="submitForm()" [disabled]="verifyOtpForm.invalid">Verify</button>
            </div>
            <div class="d-grid text-center mt-3">
              <p>
                Back to
                <a [routerLink]="['/login']" class="text-primary text-decoration-none"><strong>Login</strong></a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
